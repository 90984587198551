import { createStore } from 'vuex';
import axios from '../classes/Axios';

import draftReturnModule from './draftReturnModule';
import shipReturnModule from './shipReturnModule';
import trackReturnModule from './trackReturnModule';
import articleModule from './articleModule';
import OCProductModule from './OCProductModule';
import headerModule from './headerModule';
import orderModule from './orderModule';
import productModule from './productModule';
import merchantSetupModule from './merchantSetupModule';
import translationModule from './translationModule';
import contentModule from './contentModule';
import countriesModule from './countriesModule';
import carrierModule from './carrierModule';
import shipmentModule from './shipmentModule';
import OCformdataModule from './OCformdataModule';

const devPortal = process.env.VUE_APP_RETURNBIRD_HOST;

export default createStore({
    modules: {
        draftReturns: draftReturnModule,
        shipReturns: shipReturnModule,
        trackReturns: trackReturnModule,
        articleModule: articleModule,
        products: productModule,
        OC: OCProductModule,
        header: headerModule,
        orders: orderModule,
        mcpModule: merchantSetupModule,
        translations: translationModule,
        portalContent: contentModule,
        countries: countriesModule,
        carrierModule: carrierModule,
        shipmentModule: shipmentModule,
        OCformdataModule: OCformdataModule,
    },
    state: {
        user: null,
        hasAdditionalInfoStep: {},
        baseUrl: (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? devPortal + '/' : location.protocol + '//' + location.hostname + '/',
        portalUrl: (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? devPortal + '/portal/' : location.protocol + '//' + location.hostname + '/portal/',
        isLoggedIn: false,
        portal: null,
        accountname: null
    },
    mutations: {
        setAccountName(state, name) {
            state.accountname = name;
        },

        setCurrentUser(state, user) {
            state.user = user;
        },

        setPortal(state, portal) {
            state.portal = portal;
        },
        //isAuthenticated
        setIsLoggedIn(state, bool) {
            state.isLoggedIn = bool;
        },

        //return has additional step
        setHasAdditionalInfoStep(state, setObj) {
            state.hasAdditionalInfoStep[setObj.returnId] = setObj.hasAdditionalInfoStep;
        },
        clearAdditionalInfoStep(state) {
            state.hasAdditionalInfoStep = {};
        },

        logoutClear(state) {
            this.commit('draftReturns/clear');
            this.commit('shipReturns/clear');
            this.commit('trackReturns/clear');
            this.commit('products/clear');
            this.commit('OC/clear');
            this.commit('orders/clear');
            state.user = null;
        }
    },
    actions: {
        getPortal(state) {
            return new Promise((resolve) => {
                if (this.state.portal) {
                    resolve(this.state.portal);
                } else {
                    let url = new URL(this.state.portalUrl + 'app/getPortal');
                    axios.get(url.href).then((data) => {
                        let portal = data.data.data.portal;
                        state.commit('setPortal', portal);
                        state.commit('portalContent/set', [data.data.data.portalContent, data.data.data.currentLanguage]);
                        state.commit('setAccountName', data.data.data.accountname);
                        state.commit('translations/set', data.data.data.translations);
                        state.commit('translations/setAttributeLabels', data.data.data.attributeLabels);
                        state.commit('translations/setCurrentLanguage', data.data.data.currentLanguage);
                        state.commit('translations/setPlaceHolders', data.data.data.placeHolders);
                        state.commit('countries/set', data.data.data.portal.countries);

                        window.document.title = portal?.attributes?.RTN_PTL_NAME ?? 'Return portal';

                        resolve(portal);
                    });
                }
            });
        },
        getHasAdditionalInfoStep(state, returnId) {
            return new Promise((resolve) => {
                if (this.state.hasAdditionalInfoStep[returnId]) {
                    resolve(this.state.hasAdditionalInfoStep[returnId]);
                } else {
                    let url = new URL(this.state.portalUrl + 'return/hasAdditionalInfoStep');
                    url.searchParams.set('returnId', returnId);

                    axios.get(url.href).then((data) => {
                        const hasAdditionalInfoStep = data.data.data.hasAdditionalInfoStep;

                        state.commit('setHasAdditionalInfoStep', {
                            returnId: returnId,
                            hasAdditionalInfoStep: hasAdditionalInfoStep
                        });

                        resolve(hasAdditionalInfoStep);
                    });
                }
            });
        },
        isLoggedInRequest(state) {
            return new Promise((resolve) => {
                let url = new URL(this.state.portalUrl + 'user/isLoggedIn');
                axios.get(url.href).then((data) => {
                    let isLogged = data.data.data.isLoggedIn;
                    state.commit('setIsLoggedIn', isLogged);
                    resolve(isLogged);
                });
            });
        },
        getCurrentUser(state) {
            return new Promise((resolve) => {
                if (this.state.user) {
                    resolve(this.state.user);
                } else {
                    let url = new URL(this.state.portalUrl + 'user/getCurrentUser');
                    axios.get(url.href).then((data) => {
                        let user = data.data.data.user;
                        state.commit('setCurrentUser', user);
                        resolve(user);
                    });
                }
            });
        },
    }
});
