<template>
    <div>
        <div class="container container-full-width-md container-md-desktop pt-5">
            <label class="pb-3">
                {{ $store.state.translations.translations['Products added to your return order'] }}
            </label>

            <div v-if="!productsLoaded">
                {{ $store.state.translations.translations['Loading your products...'] }}
            </div>

            <div class="ps-2" v-else-if="$store.state.products.products[orderId] && Object.keys($store.state.products.products[orderId]).length === 0">
                {{ $store.state.translations.translations['There are no products added to your return yet'] }}
            </div>

            <template v-else>
                <div class="accordion-head">
                    <div class="accordion-head-td first"></div>

                    <template v-if="showModelNumber !== false">
                        <div class="accordion-head-td second">
                            {{ $store.state.translations.attributeLabels['RTN_PDT_MODEL_NUMBER'] }}
                        </div>

                        <div class="accordion-head-td third">
                            {{ $store.state.translations.attributeLabels['RTN_PDT_DESCRIPTION'] }}
                        </div>

                        <div class="accordion-head-td forth">
                            {{ $store.state.translations.attributeLabels['RTN_PDT_QUANTITY_REQUESTED'] }}
                        </div>
                    </template>

                    <template v-else>
                        <div class="accordion-head-td lg">
                            {{ $store.state.translations.attributeLabels['RTN_PDT_DESCRIPTION'] }}
                        </div>

                        <div class="accordion-head-td forth">
                            {{ $store.state.translations.attributeLabels['RTN_PDT_QUANTITY_REQUESTED'] }}
                        </div>
                    </template>

                    <div class="accordion-head-td first" v-if="showProductPrices">{{ $store.state.translations.attributeLabels['RTN_PDT_PRICE'] }}</div>
                    <div class="accordion-head-td first"></div>
                </div>

                <div id="accordion" class="accordion" v-for="product in $store.state.products.products[orderId]" :key="product.ProductId">
                    <div class="accordion-card">
                        <div v-if="productsBeingDeleted.indexOf(product.ProductId) != -1">
                            <div class="accordion-card-header">
                                <div class="accordion-card-header-td third">
                                    {{ $store.state.translations.translations['Deleting product...'] }}
                                </div>
                            </div>
                        </div>

                        <div v-else>
                            <div class="accordion-card-header">
                                <div class="accordion-card-header-td first">
                                    <b-button v-b-toggle="'accordion-' + product.ProductId"
                                              class="btn-link"
                                              @click="getAdditionalInfo(product.ProductId)"
                                    >
                                        <i class="icon-plus"></i>
                                    </b-button>
                                </div>

                                <template v-if="showModelNumber !== false">
                                    <div class="accordion-card-header-td second">
                                        {{ product.ModelNumber }}
                                    </div>

                                    <div class="accordion-card-header-td third">
                                        {{product.Description}}
                                    </div>

                                    <div class="accordion-card-header-td forth">
                                        {{product.Quantity}}
                                    </div>
                                </template>

                                <template v-else>
                                    <div class="accordion-card-header-td lg">
                                        {{product.Description}}
                                    </div>

                                    <div class="accordion-card-header-td forth">
                                        {{product.Quantity}}
                                    </div>
                                </template>

                                <div class="accordion-card-header-td first" v-if="showProductPrices && product.Price">
                                    {{product.CurrencySymbol}} {{product.Price}}
                                </div>

                                <div class="accordion-card-header-td first">
                                    <a href="#collapseOne" class="btn-link" data-toggle="collapse"
                                       aria-controls="collapseOne"
                                       v-on:click.prevent="deleteProduct(product.ProductId)">
                                        &times;
                                    </a>
                                </div>
                            </div>

                            <div class="accordion-card-header" v-if="product.NotReturnable === '1'">
                                <div class="accordion-card-header-td first"></div>

                                <span class="d-block mt-2 text-info">
                                    {{ $store.state.translations.translations['This product doesn\'t need to be returned'] }}
                                </span>
                            </div>
                        </div>

                        <b-collapse :id="'accordion-' + product.ProductId"
                                    accordion="products-overview-accordion"
                                    class=""
                                    aria-labelledby="headingOne"
                                    data-parent="#accordion"
                                    v-model="accordion[product.ProductId]"
                        >
                            <div class="collapse-body">
                                <div class="card-body">
                                    <ProductDetailsForm :params="params"
                                                        :productId="product.ProductId"
                                                        :articleGroupId="product.ProductGroupId"
                                                        :ref="'updateProductDetailsForm_' + product.ProductId"
                                                        :errors="errors[product.ProductId]"
                                                        @productDetailsFormLoaded="loadedForm()"
                                                        :class="productSaving ? 'd-none' : 'd-block'"
                                    />

                                    <template v-if="productSaving">
                                        <p>
                                            {{ $store.state.translations.translations['Saving product...'] }}
                                        </p>
                                    </template>

                                    <template v-else>
                                        <button type="button"
                                                class="btn md btn-primary mt-3"
                                                v-if="!isLoadingForm && accordion[product.ProductId]"
                                                v-on:click="saveProduct(product.ProductId)"
                                        >
                                            {{ $store.state.translations.translations['Save'] }}
                                        </button>
                                    </template>
                                </div>
                            </div>
                        </b-collapse>

                        <InputFieldError attribute="global" :errors="deleteErrors[product.ProductId]" class="px-3"/>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
    import {BButton, BCollapse, VBToggle} from 'bootstrap-vue-3';
    import ProductDetailsForm from './_ProductDetailsForm.vue';
    import _ from 'lodash';
    import InputFieldError from '@/components/_InputFieldError.vue';

    export default {
        components: {
            'b-button': BButton,
            'b-collapse': BCollapse,
            'ProductDetailsForm': ProductDetailsForm,
            'InputFieldError': InputFieldError,
        },
        directives: {
            'b-toggle': VBToggle,
        },
        props: [
            'params'
        ],
        data() {
            return {
                productSaving: false,
                productsBeingDeleted: [],
                products: null,
                productsLoaded: false,
                returnId: this.$route.query.returnId,
                merchantSetupId: this.$route.query.merchantSetupId,
                orderId: this.$route.query.orderId,
                productBaseUrl: this.$store.state.portalUrl + 'product/',
                productCount: 0,
                additionalInfoLoaded: false,
                customAttributes: null,
                returnReasons: {},
                serviceRequests: {},
                selectedReturnReason: null,
                selectedServiceRequest: null,
                form: {},
                accordion: {},
                isLoadingForm: true,
                errors: {},
                deleteErrors: {},
            }
        },
        created() {
            this.$store.dispatch('products/getAll', [this.returnId, this.orderId]).then(() => {
                this.productsLoaded = true;
            });
        },
        methods: {
            saveProduct(productId) {
                this.errors = {};
                this.deleteErrors = {};

                const updateProductDetailsForm = 'updateProductDetailsForm_' + productId;
                // The $ref is stored as array due to being created in a v-for loop.
                const inputData = this.$refs[updateProductDetailsForm][0].getData();
                this.productSaving = true;

                let url = new URL(this.$store.state.portalUrl + 'product/save');
                url.searchParams.set('orderId', this.orderId);
                url.searchParams.set('articleId', this.selectedArticleId);
                url.searchParams.set('productId', productId);

                let formData = new FormData();
                formData.append('Product[CUSTOM_ATTRIBUTE_OPTION]', 'SPP_PRODUCT_FEED');

                _.forOwn(inputData.attributes, function (value, key) {
                    formData.append('Product[' + key + ']', value);
                });

                _.forOwn(inputData.customAttributes, function (value, key) {
                    if (value !== null) {
                        formData.append('Product[' + key + ']', value);
                    }
                });

                this.$http.post(url.href, formData).then((data) => {
                    this.productSaving = false;

                    if (data.data.data.errors) {
                        this.errors[productId] = data.data.data.errors;

                        this.$nextTick(() => {
                            // Make sure the form is displayed correctly in case of errors.
                            this.getAdditionalInfo(productId);
                        });
                    } else {
                        let product = data.data.data.product;
                        this.additionalInfoLoaded = false;
                        this.selectedArticleId = null;
                        this.$store.commit('products/add', {orderId: this.orderId, product: product});

                        let additionalInfo = this.$store.state.products.additionalInfo[productId];

                        _.forOwn(additionalInfo.customAttributes, function (ca, key) {
                            if (ca.type === 'file' && product.customAttributes[key] && product.customAttributes[key].valueUrl) {
                                ca.value = product.customAttributes[key].value;
                                ca.valueUrl = product.customAttributes[key].valueUrl;
                            } else {
                                ca.value = inputData.customAttributes[key];
                            }
                        });

                        if (inputData.attributes.RTN_PDT_QUANTITY_REQUESTED) {
                            additionalInfo['quantityRequested'] = inputData.attributes.RTN_PDT_QUANTITY_REQUESTED;
                        }

                        if (inputData.attributes.RTN_PDT_PURCHASE_DATE) {
                            additionalInfo['purchaseDate'] = inputData.attributes.RTN_PDT_PURCHASE_DATE;
                        }

                        if (inputData.attributes.RTN_PDT_WARRANTY_DATE) {
                            additionalInfo['warrantyDate'] = inputData.attributes.RTN_PDT_WARRANTY_DATE;
                        }

                        if (inputData.attributes.RTN_PDT_RETURN_REASON) {
                            additionalInfo['returnReason'] = inputData.attributes.RTN_PDT_RETURN_REASON;
                        }

                        if (inputData.attributes.RTN_PDT_SERVICE_REQUEST) {
                            additionalInfo['serviceRequest'] = inputData.attributes.RTN_PDT_SERVICE_REQUEST;
                        }

                        this.$store.commit('products/setAdditionalInfo', {productId: productId, additionalInfo});
                        this.accordion[productId] = false;
                    }
                });
            },
            deleteProduct(productId) {
                this.deleteErrors = {};
                this.productsBeingDeleted.push(productId);

                const url = new URL(this.productBaseUrl + 'delete');
                url.searchParams.set('productId', productId);

                this.$http.post(url.href).then((data) => {
                    if (data?.data?.data?.errors) {
                        const deleteIndex = this.productsBeingDeleted.indexOf(productId);

                        if (deleteIndex > -1) {
                            this.productsBeingDeleted.splice(deleteIndex, 1);
                        }

                        this.deleteErrors[productId] = {global: data.data.data.errors};
                    } else {
                        this.$store.commit('products/delete', {productId: productId, orderId: this.orderId});

                        if (this.$store.state.OC.loaded) {
                            this.$store.commit('OC/clear');
                            this.$store.commit('OC/setLoaded', false);
                            this.$store.dispatch('OC/getAll', {returnId: this.returnId, orderId: this.orderId});
                        }

                        this.$nextTick(() => {
                            if (document.getElementById('articleSearchInputField')) {
                                document.getElementById('articleSearchInputField').focus();
                            }
                        });
                    }
                });
            },
            getAdditionalInfo(productId) {
                if (!this.$refs['updateProductDetailsForm_' + productId][0].additionalInfoLoaded) {
                    this.isLoadingForm = true;
                    this.$refs['updateProductDetailsForm_' + productId][0].getAdditionalInfo(productId);
                }
            },
            loadedForm() {
                this.isLoadingForm = false;
            }
        },
        computed: {
            showModelNumber() {
                return this.params?.showModelNumber;
            },
            showProductPrices() {
                return this.params.showProductPrices === true;
            }
        }
    }
</script>

<style scoped>

</style>
